import Helmet from 'react-helmet'
import Footer from '../../components/footer'
import ProjectCards from '../../components/projectCards'
import NavBar from "../../components/navbar"
import './style.scss'

const Projects = () => {
    return(
        <>
            <Helmet>
                <title>Наши проекты</title>
                <meta
                    name="description"
                    content="Свяжитесь с нами, чтобы получить профессиональную оценку вашего проекта от нашей команды экспертов. Мы готовы предоставить вам подробный анализ технических и бизнес-аспектов вашего проекта, а также разработать индивидуальный план действий для его успешной реализации."
                />
                <meta name="keywords"
                      content="наши проекты, портфолио, реализованные проекты, примеры работ" />
                <meta property="og:image" content={require('../consulting/assets/IMG_1825.png')} />
            </Helmet>
            <NavBar/>
            <div className="projects-container">
                <Hero/>
                <ProjectsSection />
                <Footer/>
            </div>
        </>
    )
}

const Hero = () => {
    return(
        <>  
            <section className='section-global bg-shade-1 hero'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="section-tag mb-4">Примеры выполненных работ</div>
                            <h1 className='display-1'>Эффективные и удивительные решения - наша страсть</h1>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const ProjectsSection = () => {

    const projects_data = [
        {
            category: 'Продукт',
            text: "Инфраструктура образовательной организации дистанционного образования",
            imgUrl: 'assets/electrodekanat.png',
            color: "#25CAAC",
            link: '/solutions/electrodekanat'
        },
        {
            category: 'Разработка',
            text: 'МИСАО - сайт образовательной организации',
            imgUrl: 'assets/misao.png',
            color: "#F97316",
            link: '/solutions/misao'
        },
        {
            category: 'Разработка',
            text: "Московская академия дополнительного образования",
            imgUrl: 'assets/mado.png',
            color: "#F97316",
            link: 'https://madoinst.ru'
        },

        {
            category: 'Разработка',
            text: 'Yellow line: интернет-магазин домашних аксессуаров',
            imgUrl: 'assets/yl.png',
            color: "#F97316",
            link: 'https://yellowline.nakolenke.org'
        },
        {
            category: 'SEO',
            text: "Цех 67 - интернет-магазин лабораторного оборудования. Сео-оптимизация, успешный вывод в топ",
            imgUrl: 'assets/ceh67.png',
            color: "#d932ce",
            link: 'https://ceh-67.ru'
        },
        {
            category: 'Разработка',
            text: "Больничка: CRM-система для сети ветеринарных клиник",
            imgUrl: 'assets/bolnichka.png',
            color: "#F97316",
            link: 'https://klinika.nakolenke.org'
        },
        {
            category: 'Разработка',
            text: "Upcargo: CRM-система для логистики компании по переездам и вывозу мусора",
            imgUrl: 'assets/upcargo-logo.png',
            color: "#F97316",
            link: '/solutions/upcargo'
        },
        {
            category: 'Разработка',
            text: "Никс Фарм: интернет-аптека",
            imgUrl: 'assets/nix-pharm.png',
            color: "#F97316",
            link: '/solutions/nix-pharm'
        },
        {
            category: 'DEVOPS',
            text: "Сопровождение специализированных релизов и кастомных сборок платформы дистанционного образования  Moodle",
            imgUrl: 'assets/misao-moodle.png',
            color: "#e791ac",
            link: 'https://vo.misaoinst.ru'
        },
        {
            category: 'Продукт',
            text: "SICKLE.PRO: распределенная защищенная инфраструктура для мониторинга и управления майнинг-оборудованием",
            imgUrl: 'assets/sickle.png',
            color: "#25CAAC",
            link: '/solutions/sickle'
        },
    ]

    return(
        <>
            <section className='section-global'>
                <div className="container">
                    <div className="row">
                        <ProjectCards data={projects_data} src="projects" />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Projects
