import Helmet from "react-helmet"
import Footer from "../../components/footer"
import NavBar from "../../components/navbar"
import './style.scss'

const Tmp = () => {
    return(
        <>
            <Helmet>
                <title>Аутсорсинг - системное администрирование и разработка веб-сайтов</title>
                <meta
                    name="description"
                    content="Извините, данная страница находится в разработке. Мы работаем над ней и скоро представим вам полную версию. Следите за нашими обновлениями."
                />
                <meta name="keywords"
                      content="страница в разработке, разработка сайта, обновления, coming soon, системное администрирование" />
            </Helmet>
            <NavBar />
            <section className="section-global bg-shade-1 hero nf-section">
                <div className="container">
                    <div className="row consulting pt-5">

                        <div className="col-12 text-center pb-5">
                            <img src={require('./assets/razrabotke.jpg')} className="img-fluid hero-banner"
                                 alt='баннер разработка'/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 text-center">
                            <h1>Страница в разработке </h1>
                            <p className="txt-1">И скоро будет опубликована</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default Tmp