import Helmet from 'react-helmet'
import Footer from '../../components/footer'
import './style.scss'
import NavBar from "../../components/navbar"

const Contact = () => {
    return (
        <>
            <Helmet>
                <title>Заявка на оценку проекта</title>
                <meta
                    name="description"
                    content="Свяжитесь с нами, чтобы получить профессиональную оценку вашего проекта от нашей команды экспертов. Мы готовы предоставить вам подробный анализ технических и бизнес-аспектов вашего проекта, а также разработать индивидуальный план действий для его успешной реализации."
                />
                <meta name="keywords"
                      content="контакты, запрос, оценка проекта, обратная связь, бизнес-организация, IT под ключ" />
                <meta property="og:image" content={require('../consulting/assets/IMG_1825.png')} />
            </Helmet>
            <NavBar/>
            <div className="contact-container">
                <ContactSection />
                <Footer/>
            </div>
        </>
    )
}

const ContactSection = () => {

    const features_data = [
        "Отправляя данную форму вы соглашаетесь с политикой конфиденциальности",
    ]

    return (
        <>
            <section className='section-global'>
                <div className="container container-2">



                    <div className="row gy-5 pt-5">



                        <div className="col-lg-6">

                            <h2 className='mb-16'>Остались вопросы?</h2>
                            <p className="txt-1">Расскажите о вашей задаче. Свяжемся в течение рабочего дня и начнем совместную продуктивную работу.</p>
                            {features_data.map((e, i) => <div key={i} className="txt-2 color-1 fw-500 mb-8 d-flex align-items-center">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2'>
                                    <rect width="24" height="24" rx="12" fill="#0FA958"/>
                                    <path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>


                                {e}
                            </div>)}
                        </div>
                        <div className="col-lg-6">
                            <form className="row g-3" action="https://formspree.io/f/xwkjazrj"
                                  method="POST">
                                <div className="col-md-12 col-12">
                                    <label className="form-label">Ваше имя</label>
                                    <input type="text" className="form-control" placeholder="" required name="name" />
                                </div>

                                <div className="col-12">
                                    <label className="form-label">Ваша электронная почта</label>
                                    <input type="email" className="form-control" placeholder="" required name="email" />
                                </div>
                                <div className="col-md-12">
                                    <textarea className="form-control" rows="6" placeholder="Чем мы можем вам помочь?" required name="message"></textarea>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary btn-lg">Отправить</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="row consulting pt-5">
                        <div className="col-12 text-center">
                            <img src={require('./assets/watch.jpg')} className="img-fluid hero-banner"
                                 alt='баннер услуги'/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact