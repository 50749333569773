import Helmet from 'react-helmet'
import Footer from '../../components/footer'
import NavBar from "../../components/navbar"
import './style.scss'

const Sickle = () => {
    return(
        <>
            <Helmet>
                <title>Майнинговая система sickle.pro</title>
            </Helmet>
            <NavBar/>
            <div className="project-detail-container">
                <Hero/>
                <CaseStudy/>
                <Footer/>
            </div>
        </>
    )
}

const Hero = () => {
    return(
        <>  
            <section className='section-global bg-shade-1 hero'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="section-tag mb-8">Разработка</div>
                            <h1 className='display-1'>Проектирования и разработка экосистемы для майнингово оборудования</h1>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const CaseStudy = () => {
    return(
        <>
            <section className='section-global case-study'>
                <div className="container container-2">
                    <div className="row">
                        <div className="col-12 text-center mb-20">
                            <img src={require('../projects/assets/sickle.png')} className="img-fluid mb-20" alt="sickle.pro майнинг" />
                        </div>
                        <div className="col-12">
                            <div className="case-study-section">
                                <h2 className="display-2">Задача</h2>
                                <p className='txt-1'>Создать распределенную масштабируемую сеть для площадок с майнинговым оборудованием (gpu-фермы/asic-блок-майнеры)
                                    с удобной возможностью администрирования, конфигурирования и мониторинга.</p>
                                <p className='txt-1'>
                                    <img src={require('./assets/sickle1.jpg')} alt="скриншот 1" className='img-fluid mt-5' />
                                </p>
                                <h2 className="display-2">Как работает</h2>
                                <p className="txt-1">В качестве площадки выступает транспортный контейнер, переделанный под майнинг ферму - так называемая пасека. На уровне доступа пасеки - управляемые свитчи Dlink или Microtic. На уровне распределения - Microtik Hex.
                                    На роутере работает кеширующий днс, из единой базы dhcp-релей раздает клиентам (ульям) адреса и параметры: GPU-фермы используют протокол pxe и подготовленные заранее live-образы операционной системы, загружаются с локального сервера, настройки приносятся автоматические при запуске и во время работы.
                                    ASIC-блоки контролируются через ssh.
                                    Каждая пасека подключается к ядру сети по VPN-тоннелю (обычно L2TP/IPSEC), в зависимости от географического расположения (пасеки могут быть размещены в разных странах)</p>

                                <p className="txt-1"><img src={require('./assets/sickle-scheme.png')} alt="схема сети" className='img-fluid mt-5' /></p>
                                <h2 className="display-2">Какие технологии используются</h2>
                                <p className="txt-1">
                                    Система распределения и контроля адресов и конфигурация загрузки по сети:
                                    <ul>
                                        <li>Isc-dhcp44-server/dhcp relay + freeradius + radius api + mysql.</li>
                                    </ul>
                                </p>

                                <p className="txt-1">Загрузка headless-ульев (майнинг-нод):
                                    <ul>
                                    <li>PXE-boot (dhcp options 6, 66, 67) + tftp.</li>
                                        <li>PXE-сервер на базе Raspberry Pi 3 + rsync до основого хранилища образов.</li>
                                        <li>В качестве live-os - подготовленные образы Debian Linux.</li>
                                    </ul>
                                </p>

                                <p className="txt-1">Ядро сети (организация отказоусточивого маршрутизатора / концентратора VPN-тоннелей)
                                    <ul>
                                    <li>freebsd CARP + pf + mpd5 + strongswan + frr</li>
                                </ul>
                                </p>
                                <p className="txt-1">
                                    Для управления конфигурациями и пакетами - Puppet / PuppetDB + api.
                                </p>
                                <p className="txt-1">Мониторинг нод - Graphite + Grafana.</p>
                                <p className="txt-1">
                                <img src={require('./assets/sickle2.jpg')} alt="веб-интерфейс 3" className='img-fluid mt-5' />
                                </p>
                                <p className="txt-1">Централизованное логирование - graylog + api.</p>
                                <p className="txt-1">Для управления и конфигурации пасек/нод на php + laravel написан веб-сервис.</p>
                                <p className="txt-1">
                                    <img src={require('./assets/sickle3.jpg')} alt="веб-интерфейс 3" className='img-fluid mt-5' />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Sickle
