import Helmet from 'react-helmet'
import Footer from '../../components/footer'
import NavBar from "../../components/navbar"
import './style.scss'

const Misao = () => {
    return(
        <>
            <Helmet>
                <title>Разработка веб-сайта и бэк-офиса образовательной организации</title>
            </Helmet>
            <NavBar/>
            <div className="project-detail-container">
                <Hero/>
                <CaseStudy/>
                <Footer/>
            </div>
        </>
    )
}

const Hero = () => {
    return(
        <>  
            <section className='section-global bg-shade-1 hero'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="section-tag mb-8">Разработка</div>
                            <h1 className='display-1'>Современный и функциональный сайт образовательной организации</h1>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const CaseStudy = () => {
    return(
        <>
            <section className='section-global case-study'>
                <div className="container container-2">
                    <div className="row">
                        <div className="col-12 text-center mb-20">
                            <img src={require('../projects/assets/misao.png')} className="img-fluid mb-20" alt="CaseStudy" />
                        </div>
                        <div className="col-12">
                            <div className="case-study-section">                            
                                <h2 className='display-2'><a href="https://misaoinst.ru">Московский институт современного академического образования</a> </h2>
                                <p className='txt-1'>В наследство достался достаточно запущенный веб-сайт: платформа на wordpress, версия древняя, модули устаревшие, куча уязвимостей, кривой дизайн, никакой оптимизации. Сложно поддерживать, решено сделать все по уму.</p>
                                <h2 className="display-2">Стек</h2>
                                <p className="txt-1">Laravel [PHP / MariaDB / Redis / Meilisearch] для сайта и админки  + микросервис на FastAPI [Python] для системы документооборота и работы с ЭЦП</p>
                                <h2 className="display-2">Что сделано</h2>
                                <p className="txt-1">
                                    Минимализм в дизайне, понятное интуитивное расположение элементов, навигация по разделам.
                                    Проведены все сео-оптимизации, внедрена микроразметка schema.org, виджеты, формируется фид яндекс-образования.
                                    Полнотекстовая поисковая система по страницам сайта, по pdf-файлам из системы документооборота на сайте и по внешним ссылкам сайта подразделения института.
                                    Для отдела маркетинга и рекламы выполнена интеграция в 1С Bitrix.
                                <img src={require('./assets/img2.jpg')} alt="скриншот 1" className='img-fluid mt-5' />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Misao
