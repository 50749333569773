import './style.scss'

const ExternalLink = ({ to, className, children }) => (
    <a href={to} className={className} rel="noopener noreferrer">
        {children}
    </a>
);

const ProjectCards = ({data,src}) => {
    return(
        <>
            {
                data.map((e,i)=>
                    <div className="col-xl-4 col-lg-6 col-md-6 gy-4" key={i}>
                        <ExternalLink to={e.link} className="project-link">
                        <div className='project-card d-flex flex-column' style={{background: `${e.color}1a`}}>
                                <div className='tag fw-500' style={{color: `${e.color}`}}>{e.category}</div>
                                <div className='text txt-2 color-1 mb-16 fw-700'>{e.text}</div>
                                <div className='project-thumb mt-auto'>
                                    <img src={require(`../../pages/${src}/${e.imgUrl}`)} className="img-fluid" alt={e.category}/>
                                    <div className="project-link-overlay">
                                        подробнее
                                    </div>
                                </div>
                            </div>
                        </ExternalLink>
                    </div>
                )
            }
        </>
    )
}

export default ProjectCards