import './style.scss'
import contact1 from './assets/contact_ico1.svg';
import contact2 from './assets/contact_ico2.svg';

const Footer = () => {
    return (
        <>
            <section className='section-global dark'>
                <div className="container container-2">
                    <div className="row gy-4">
                        <div className="col-lg-6 col-md-6">
                            <h2 className='mb-16'>IT-инфраструктура для бизнеса</h2>
                            <p className='txt-2'>Мы уделяем особое внимание ценностям бизнеса наших клиентов, тщательно изучаем его ключевые требования и выстраиваем нашу работу по созданию и совершенствованию инфраструктуры, чтобы соответствовать этим требованиям.</p>
                        </div>
                        <div className="col-lg-4 offset-lg-2 col-md-6">
                            <div className="contact-link-container">
                                        <a href="mailto:hello@nakolenke.org" className="contact-link">
                                            <div className="contact-link-container d-flex align-items-center">
                                                <img src={contact1} height="50" alt='электронная почта' />
                                                <div className='ms-3'>
                                                    <div className="txt-2 fw-500 color-1">Электропочта</div>
                                                    <div className="txt-3">hello@nakolenke.org</div>
                                                </div>
                                            </div>
                                        </a>
                                <a href="mailto:hello@nakolenke.org" className="contact-link">
                                    <div className="contact-link-container d-flex align-items-center">
                                        <img src={contact2} height="50" alt='телефон контакт' />
                                        <div className='ms-3'>
                                            <div className="txt-2 fw-500 color-1">Телефонная трубка</div>
                                            <div className="txt-3">пока молчит</div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='portfolio-footer-bottom dark'>
                <div className="container container-2 py-4">
                    <div className="row">
                        <div className="col-12 d-flex flex-wrap justify-content-between">
                            <div className="copyright txt-3">© 2023 НаКоленке дот орг</div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default Footer