import Helmet from 'react-helmet'
import Footer from '../../components/footer'
import './style.scss'
import NavBar from "../../components/navbar"

const About = () => {
    return(
        <>
            <Helmet>
                <title>Команда на аутсорсинг ит проектов</title>
                <meta
                    name="description"
                    content="Команда IT-специалистов готова помочь вашей организации в усилении IT-отдела. Мы готовы предложить решения, которые помогут вашей компании повысить эффективность и конкурентоспособность в условиях быстро меняющейся технологической среды."
                />
                <meta name="keywords"
                      content="аутсорсинг айти, it инфраструктура организации под ключ, разработка веб-сайтов, devops, удаленное администрирование организаци" />
                <meta property="og:image" content={require('../consulting/assets/IMG_1826.png')} />
            </Helmet>
            <NavBar/>
            <div className="about-container">
                <Team />
                <Footer />
            </div>
        </>
    )
}

const Team = () => {
    const team_data = [
        {
            name: 'Aндрeй Гришкoв',
            position: 'CEO / DEVOPS / fullstack-разработчик',
            imgUrl: 'assets/andrei.jpg'
        },
        {
            name: 'Иринa Тяпaевa',
            position: 'Менеджмент / контент-менеджмент / SEO',
            imgUrl: 'assets/irina.jpg'
        },
        {
            name: 'Ярoслaв Кoбякoв',
            position: 'Python-разработчик',
            imgUrl: 'assets/yarik.jpg'
        },
        {
            name: 'Дмитpий Зыкoв',
            position: 'php-разработчик',
            imgUrl: 'assets/dima.jpg'
        }
    ]
    return(
        <>
            <section className='section-global bg-shade-blue'>
                <div className="container container-2">
                    <div className="row mb-40">
                        <div className="col-lg-8">
                            <div className="section-tag mb-8"><h1>Команда</h1></div>
                            <h2 className='mb-16'>Дружная команда опытных специалистов, которая поможет вашему бизнесу расти.</h2>
                        </div>
                    </div>
                    <div className="row gy-4">
                        {team_data.map((e,i)=>
                            <div className="col-lg-3 col-md-4 col-sm-6" key={i}>
                                <div className="team-member text-center">
                                    <img src={require(`./${e.imgUrl}`)} className="img-fluid mb-16" alt={e.name} />
                                    <div className="txt-2 color-1 fw-500">{e.name}</div>
                                    <div className="txt-3">{e.position}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}



export default About