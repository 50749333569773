import Helmet from 'react-helmet'
import Footer from '../../components/footer'
import NavBar from "../../components/navbar"
import './style.scss'

const Sickle = () => {
    return(
        <>
            <Helmet>
                <title>Разработать CRM для логистики</title>
            </Helmet>
            <NavBar/>
            <div className="project-detail-container">
                <Hero/>
                <CaseStudy/>
                <Footer/>
            </div>
        </>
    )
}

const Hero = () => {
    return(
        <>  
            <section className='section-global bg-shade-1 hero'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="section-tag mb-8">Разработка</div>
                            <h1 className='display-1'>Разработка CRM системы для логистической фирмы</h1>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const CaseStudy = () => {
    return(
        <>
            <section className='section-global case-study'>
                <div className="container container-2">
                    <div className="row">
                        <div className="col-12 text-center mb-20">
                            <img src={require('../projects/assets/upcargo-logo.png')} className="img-fluid mb-20" alt="upcargo бэкофис система" />
                        </div>
                        <div className="col-12">
                            <div className="case-study-section">
                                <h2 className="display-2">Задача</h2>
                                <p className='txt-1'>Создать crm-систему для обработки заказов с форм веб-сайтов, телефонных звонков для фирмы, занимающейся грузоперевозками, квартирными переездами и вывозом мусора. Необходимо отслеживать машины по GPS, управлять заказами, вести базу клиентов, взаимодействовать с водителями, считать аналитику.</p>

                                <h2 className="display-2">Стек</h2>
                                <p className="txt-1">Laravel [PHP / MariaDB / Redis]</p>

                                <p className='txt-1'>
                                    <img src={require('./assets/upcargo1.jpeg')} className="img-fluid mb-20" alt="upcargo crm" />
                                </p>
                                <h2 className="display-2">Как работает</h2>
                                <p className="txt-1">
                                    Основной сервис написан на php-фреймворке laravel, представляет собой api собственно для crm + дополнительный api для андроид-приложения для водителей с отслеживанием устройства. Bootstrap4 выбран в качестве css фреймворка для фронтенда. Мобильное приложение разрабатывали подрядчики
                                </p>
                                <p className="txt-1">Произведена интеграция
                                <ul>
                                    <li>Колтрекинговая система ip-телефонии mango-office для обработки звонков (через встроенный api).</li>
                                    <li>GPS-трекинговая система wialon.com (api).</li>
                                    <li>Смс-уведомления через sms4b.ru.</li>
                                </ul>
                                </p>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Sickle
