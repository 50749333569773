import Helmet from "react-helmet"
import { Link } from "react-router-dom"

import ContactSection from "../../components/contactSection"
import Footer from "../../components/footer"
import NavBar from "../../components/navbar"
import ServiceCards from "../../components/serviceCards"
import './style.scss'

const Consulting = () => {
    return(
        <>
            <Helmet>
                <title>Проектируем, строим и поддерживаем IT-инфраструктуру</title>
                <meta
                    name="description"
                    content="Комплексные решения и развитие ит инфраструктуры для бизнеса. Разработка веб-сайтов и сервисов, аутсорсинг управления серверами на проектной основе и внедрение devops-практик."
                />
                <meta name="keywords"
                      content="инфраструктура под ключ, разработка сайтов, создание веб-приложений, аутсорс it, devops outsource" />
                <meta property="og:image" content={require('./assets/IMG_1827.png')} />
            </Helmet>
            <NavBar/>
            <div className="consulting">
                <Hero/>
                <Services/>
                <About/>
                <Contact/>
                <Footer/>
            </div>
        </>
    )
}
const Hero = () => {
    return(
        <>
            <section className="section-global bg-shade-green hero">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-xl-6 col-lg-5 align-self-center">
                            <h1>Оптимизируем ваше ИТ</h1>
                            <p className='txt-1 mb-20 mt-16'>Решения на коленке - это едкая ирония над сложившимися в индустрии решениями, вследствии популяризации професии программиста и работы в сфере информационных технологий в целом. Ирония над классическим подходом, когда решение задачи нужно вчера, а сегодня его уже лепят из грязи и палок.</p>
                            <p>Мы занимаемся тем, что избавляем бизнес от подобных антипаттернов. Принимаем с сохой, оставляем с фабричным конвеером по производству крутоты.</p>
                            <a href="/#servicesSection" class='btn btn-primary btn-lg'>Нажмите, если заинтригованы</a>
                        </div>
                        <div className="col-xl-6 col-lg-7 align-self-center d-flex justify-content-center text-center">
                            <div style={{maxWidth: '650px'}}>
                                <img src={require('./assets/IMG_1827.png')} className="img-fluid hero-banner" alt='баннер услуги' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const Services = () => {
    const services = [
        {
            name: 'Администрирование',
            info: 'Возьмемся поддерживать ваши сервера, развивать ИТ-инфраструктуру любой сложности. Спроектируем и построим новую под конкретные задачи.',
            link: '/services',
            icoUrl: 'assets/service_ico7.svg'
        },
        {
            name: 'Разработка сайтов и сервисов',
            info: 'Сделаем крутой интернет-магазин или разработаем сложный веб-сервис. Интегрируем одно в другое. Поможем с СЕО-продвижением, SMM и контентом.',
            link: '/services',
            icoUrl: 'assets/service_ico5.svg'
        },
        {
            name: 'DEVOPS/SRE',
            info: 'Поможем применить современные методологии к вашим подходам к разработке ПО. Сократим срок доставки вашего продукта на рынок, повлияем на KPI отделов разработки/тестирования/администрирования, обеспечивая стабильную и надежную работу вашего бизнеса.',
            link: '/services',
            icoUrl: 'assets/service_ico3.svg'
        },
        {
            name: 'Информационная безопасность',
            info: 'Проведем экспертизу вашей инфраструктуры или сервиса. Изучим модели угроз, выявим слабые места, поможем защититься от утечек и киберунижений.',
            link: '/services',
            icoUrl: 'assets/service_ico6.svg'
        }
    ]
    return(
        <>
            <section className="section-global" id="servicesSection">
                <div className="container">
                    <div className="row mb-40">
                        <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
                            <div className="section-tag mb-8">Чем мы можем помочь</div>
                            <h2>Предлагаем достаточно широкий спектр услуг в современном мире информационных технологий</h2>
                        </div>
                    </div>
                    <div className="row">
                        {
                            services.map((service,i) => 
                                <div className="col-lg-4 col-xl-3 col-md-6 col-sm-6 gx-5 gy-4"  key={i}>
                                    <ServiceCards data={service} src="consulting"/>
                                </div>                    
                            )
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

const About = () => {

    const features_data = [
        "Оценим задумку, спланируем архитектуру, подберем стек",
        "Поможем с составлением ТЗ",
        "Напишем и запустим современный веб-сервис",
        "Обеспечим поддержку и любые интеграции",
        "Поможем с SEO и контентным продвижением"
    ]

    return(
        <>
            <section className="section-global bg-shade-1">
                <div className="container">
                    <div className="row mb-40 gy-4">
                        <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
                            <div style={{maxWidth: '600px'}}>
                                <img src={require('./assets/IMG_1826.png')} className="img-fluid hero-banner" alt="About"/>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <h2 className='mb-16'>Базовое администрирование</h2>
                            <p className='txt-1'>
                                Освободим ваш отдел разработки от рутинных задач по инфраструктуре: установим актуальное ПО, грамотно сконфигурируем сервисы, настроим бэкапы, подключим системы мониторинга, будем следить за стабильной работой ваших серверов 24 часа в сутки. Обеспечим информационную безопасность и разделение полномочий на всей инфраструктуре.
                                <br/><br/>
                                Обеспечим стабильное функционирование бизнеса, завязанного на ИТ.
                            </p>
                            <div className='mt-16'>
                                <Link to="/about" className='arrow-link'>
                                    Узнать больше
                                    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='ms-1'>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="currentColor"/>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-5 gy-4">
                        <div className="col-lg-6 align-self-center">
                            {/*<div className="section-tag mb-8">Решения</div>*/}
                            <h2 className='mb-16'>Разработка веб-приложений</h2>
                            <p className='txt-1'>От интернет-магазина до сложной CRM-системы - разработаем веб-сервис под нужды вашего бизнеса.</p>
                            {features_data.map((e,i)=> <div key={i} className="txt-2 color-1 fw-500 mb-8 d-flex align-items-center">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2'>
                                    <rect width="24" height="24" rx="12" fill="#0FA958"/>
                                    <path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>


                                {e}
                            </div>)}
                            <div className='mt-16'>
                                <Link to="/about" className='arrow-link'>
                                    Узнать больше
                                    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='ms-1'>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="currentColor"/>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
                            <div style={{maxWidth: '600px'}}>
                                <img src={require('./assets/IMG_1825.png')} className="img-fluid hero-banner" alt="о решениях"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}



const Contact = () => {

    const contact_data = {
            title: 'Заинтересованы в сотрудничестве?',
            title_highlight: "Оставьте заявку на консультацию",
            text: "И мы свяжемся с вами в кратчайшие сроки",
            link: '/contact'
        }

    return(
        <>
            <ContactSection contact_data={contact_data}/>
        </>
    )
}


export default Consulting