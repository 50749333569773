import { useEffect } from 'react';
import Helmet from 'react-helmet';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import { YMInitializer } from '@appigram/react-yandex-metrika';
import About from './pages/about';
import Consulting from './pages/consulting';
import Contact from './pages/contact';
import Tmp from './pages/tmp';
import Projects from './pages/projects';
import Misao from "./pages/misao";
import Sickle from "./pages/sickle";
import Upcargo from "./pages/upcargo";
import NixPharm from "./pages/nix-pharm";
import ElectroDekanat from "./pages/electrodekanat";

function Stact() {
  return (
    <>
      <Helmet>
      </Helmet>
      <YMInitializer accounts={[93379897]} options={{webvisor: true}} version="2" />
      <div className="stact-container">
        <Router>
          <ScrollToTop/>
            <Routes>
              <Route path='/' element={<Consulting />} />
              <Route path='/solutions' element={<Projects />} />
              <Route path='/solutions/misao' element={<Misao />} />
              <Route path='/solutions/sickle' element={<Sickle />} />
              <Route path='/solutions/upcargo' element={<Upcargo />} />
              <Route path='/solutions/nix-pharm' element={< NixPharm />} />
              <Route path='/solutions/electrodekanat' element={< ElectroDekanat />} />
              <Route path='/team' element={<About/>} />
              <Route path='/contact' element={<Contact/>} />
              <Route path='/blog' element={<Tmp/>} />
              <Route path='/services' element={<Tmp/>} />
              <Route path='*' element={<Tmp/>} />
            </Routes>
        </Router>
      </div>
    </>
  );
}

const ScrollToTop = () => {
  const {pathname} = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null
}

export default Stact;
