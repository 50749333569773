import Helmet from 'react-helmet'
import Footer from '../../components/footer'
import NavBar from "../../components/navbar"
import './style.scss'

const ElectroDekanat = () => {
    return(
        <>
            <Helmet>
                <title>Инфраструктура образовательной организации дистанционного образования</title>
            </Helmet>
            <NavBar/>
            <div className="project-detail-container">
                <Hero/>
                <CaseStudy/>
                <Footer/>
            </div>
        </>
    )
}

const Hero = () => {
    return(
        <>  
            <section className='section-global bg-shade-1 hero'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="section-tag mb-8">Разработка</div>
                            <h1 className='display-1'>Проектирования и разработка экосистемы дистанционного образования</h1>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const CaseStudy = () => {
    return(
        <>
            <section className='section-global case-study'>
                <div className="container container-2">
                    <div className="row">
                        <div className="col-12 text-center mb-20">
                            <img src={require('../projects/assets/electrodekanat.png')} className="img-fluid mb-20" alt="sickle.pro майнинг" />
                        </div>
                        <div className="col-12">
                            <div className="case-study-section">
                                <h2 className="display-2">Задача</h2>
                                <p className='txt-1'>Спроектировать и разработать информационную инфраструктуру для нужд образовательной организации в виде готового продукта на основе имеющихся программных платформ.</p>

                                <h2 className="display-2">Как было</h2>
                                <p className="txt-1">В качестве системы дистанционного образования выступал LMS Moodle, устаревших версий на разных площадках. Плюс два разрозненных сайта.</p>

                                <h2 className="display-2">Как стало</h2>
                                <p className="txt-1"><img src={require('./assets/scheme.png')} alt="схема проекта" className='img-fluid mt-5' /></p>

                                <p className="txt-1">
                                    Центральным местом управления была выбрана самописная CRM-система управления образовательной организацией Электродеканат, разбитая на две составные:
                                    <ul>
                                        <li>Фронтенд - Vue3</li>
                                        <li>Бэкэнд - Laravel 9 / php81 / mysql (mariaDB) / ldap</li>
                                    </ul>
                                    CRM используется для управления студентами, ведением базы данных обучающихся,
                                    отслеживанием их прогресса, работы с документацией, маркетингом и обработкой заявок из источников лидов (mango telecom voip & sip api / metrika api), аналитики.
                                </p>

                                <p className="txt-1">
                                    <p className="txt-1"><img src={require('./assets/electro1.jpg')} alt="интерфейс деканата" className='img-fluid mt-5' /></p>
                                </p>

                                <p className="txt-1">Сотрудники организации проходят авторизацию и аутентификацию в распределенном домене ActiveDirectory для работы с интересующими сервисами.
                                    <ul>
                                        <li>Таск трекинг, управление внутренними проектами, хранение кода - redmine + git + ldap.</li>
                                        <li>Работа с ЭЦП и документооборот - веб-сервис на python + fastapi + SMB сетевой диск + ldap.</li>
                                        <li>Управление контентом на сайте ОО - Laravel Nova + ldap.</li>
                                    </ul>
                                </p>

                                <p className="txt-1">Точкой входа для студентов и преподавателей является личный кабинет (Laravel 9 / php81 / mysql (mariaDB), который в зависимости от роли пользователя
                                переводит его на нужную сущность.
                                </p>


                                <p className="txt-1"><img src={require('./assets/electro2.jpg')} alt="экран сдо 1" className='img-fluid mt-5' /></p>

                                <p className="txt-1">LMS система Moodle приведена к общему виду, разработана собственная поддерживаемая сборка необходимых модулей и плагинов в зависимости от уровня образования.
                                </p>

                                <p className="txt-1"><img src={require('./assets/electro3.jpg')} alt="экран сдо 2" className='img-fluid mt-5' /></p>


                                <h2 className="display-2">Сопровождение</h2>
                                <p className="txt-1">С помощью внедрения методик devops сокращено время разработки, сопровождения и деплоя новых версий систем дистанционного обучения.
                                Инфраструктурные сервисы распространяются в виде контейнеров docker, docker swarm, конфигурации хранятся в puppet + svn. Хранение и разбор логов - graylog, мониторинг и алертинг - grafana.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ElectroDekanat
