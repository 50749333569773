import Helmet from 'react-helmet'
import Footer from '../../components/footer'
import NavBar from "../../components/navbar"
import './style.scss'

const NixPharm = () => {
    return(
        <>
            <Helmet>
                <title>Разработка интернет-аптеки</title>
            </Helmet>
            <NavBar/>
            <div className="project-detail-container">
                <Hero/>
                <CaseStudy/>
                <Footer/>
            </div>
        </>
    )
}

const Hero = () => {
    return(
        <>  
            <section className='section-global bg-shade-1 hero'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="section-tag mb-8">Разработка</div>
                            <h1 className='display-1'>Разработка интернет-каталога для молодой аптечной сети</h1>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const CaseStudy = () => {
    return(
        <>
            <section className='section-global case-study'>
                <div className="container container-2">
                    <div className="row">
                        <div className="col-12 text-center mb-20">
                            <img src={require('../projects/assets/nix-pharm.png')} className="img-fluid mb-20" alt="nix-pharm аптека" />
                        </div>
                        <div className="col-12">
                            <div className="case-study-section">
                                <h2 className="display-2">Задача</h2>
                                <p className='txt-1'>Правительство разрешило торговлю лекарственными препаратами через интернет. Нужно наладить новый канал сбыта качественной продукции, красиво вписав его в существующий бизнес-процесс закупки, доставки и логистики в двух аптечных точках.</p>
                                <p className="txt-1">
                                    BTW, сетевая инфраструктура аптечных пунктов, видеонаблюдение, терминальный сервер бухгалтерии, рабочие места фармацевта спроектированы и администрируются нашей компанией.
                                </p>
                                <h2 className="display-2">Стек</h2>
                                <p className="txt-1">Laravel [PHP / MariaDB / Redis]</p>

                                <p className='txt-1'>
                                    <img src={require('./assets/nix1.jpg')} className="img-fluid mb-20" alt="никс фарм 1" />
                                </p>
                                <h2 className="display-2">Как работает</h2>
                                <p className="txt-1">
                                    Основной сервис написан на php-фреймворке laravel, представляет собой классический монолит с интеграцией каталога из фармацевтической товарной складской системы Фармнет.
                                </p>
                                <p className='txt-1'>
                                    <img src={require('./assets/nix2.jpg')} className="img-fluid mb-20" alt="никс фарс 2 скрин" />
                                </p>

                                <p className='txt-1'>
                                    <img src={require('./assets/nix3.jpg')} className="img-fluid mb-20" alt="админка" />
                                </p>

                                <p className='txt-1'>
                                    <img src={require('./assets/nix4.jpg')} className="img-fluid mb-20" alt="upcargo crm" />
                                </p>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NixPharm
