import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import Stact from './Stact';


const root =  ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <Stact   />
  </React.StrictMode>
);
reportWebVitals();
